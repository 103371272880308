import React, { useContext } from "react";
import { QuestionSection, Radio } from "../../components/utility/quote";
import { navigate } from "gatsby-link";

import InternetContext from "../../contexts/internetContext";

const Next = "/internet/Questions/4";
const Points = [
  {
    question: "Please share your address",
    progress: "75%",
    options: [
      { answer: "Street Address", to: Next, icon: "" },
      { answer: "Line 2", to: Next, icon: "" },
      { answer: "State/Region/Province", to: Next, icon: "" },
      { answer: "Audio/Video Conferencing", to: Next, icon: "" },
      { answer: "Postal code", to: Next, icon: "" },
    ],
  },
];
export default function Question3({ title }) {
  const { que3, setQue3 } = useContext(InternetContext);

  return Points.map((item) => {
    return (
      <QuestionSection
        title={title}
        question={item.question}
        progress={item.progress}
      >
        {item.options.map((items) => {
          return (
            <Radio
              key={items.answer}
              icon={items.icon}
              value={items.answer}
              title={items.answer}
              isSelected={que3 === items.answer}
              onChange={(e) => {
                setQue3(e.target.value);
                navigate(items.to);
              }}
            />
          );
        })}
      </QuestionSection>
    );
  });
}
