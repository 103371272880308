import { createContext } from "react";
const initialstate = {
  que1: "",
  que2: "",
  que3: "",
  que4: "",
};
const InternetContext = createContext(initialstate);
InternetContext.displayName = "InternetContext";

export default InternetContext;
